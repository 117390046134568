import React, { useState } from 'react'
import { FaCaretDown } from 'react-icons/fa'
import styled from 'styled-components'


const ButtonDrop = (props) => {
  const [down, setDown] = useState(false)
      return (
      <Wrapper>
      <div>
          <div className='g-btn-container'>
        <button
          type='button'
          className='g-btn'
          onClick={() => setDown(!down)}
        > 
        Submit
          <FaCaretDown />
        </button>
        <div className= {down ? 'dropdown show-dropdown':'dropdown' }>
           <div className='g-btn-container g-btn4'> <button
           type='button'
            className='dropdown-btn'
                  onClick={() => {
                  props.handleSubmit()
                  }}
             > Publish
              </button>
              </div>
              
              <div className='g-btn-container g-btn4'> <button
               type='button'
              className='dropdown-btn'
              onClick={() => {
            props.handleDraft()
              }}
               > Draft
               </button>
              
               </div>   
             </div>
        </div>
                </div>
          
            </Wrapper>
  )
}

export default ButtonDrop




const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  z-index:100;
  
 .toggle-btn {
    background: transparent;
    border-color: transparent;
    font-size: 1.75rem;
    color: var(--primary-500);
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  background: var(--white);
  .g-btn-container {
    position: relative;
  }
  button{
margin: 4px
  }
  .g-btn4:hover{
background: white;
border-Radius:4px
}
 .seperate{
    padding: 4px
 }
 
  .g-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
    position: relative;
    box-shadow: var(--shadow-2);
  }
  .dropdown {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background: var(--primary-100);
    box-shadow: var(--shadow-2);
    padding: 0.5rem;
    text-align: center;
    visibility: hidden;
    border-radius: var(--borderRadius);
  }
  .show-dropdown {
    visibility: visible;
  }
  .dropdown-btn {
    background: transparent;
    border-color: transparent;
    color: var(--primary-500);
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
    cursor: pointer;
  }
  .logo-text {
    display: none;
    margin: 0;
  }
`