import {  Route, Routes } from "react-router-dom";
import Login from "./pages/admin/users/Login";
import  Categories from "./pages/admin/category/Categories";
import  EditCategory from "./pages/admin/category/EditCategory";
import CreateCategories from "./pages/admin/category/CreateCategories";
import ShareLayout from './pages/admin/ShareLayout'
import Products from "./pages/admin/Product/Products";
// import Boxes from "./pages/admin/Product/Products";
import ProtectedRoutes from "./hoc/ProtectedRoutes";
import ProductEdit from "./pages/admin/Product/ProductEdit";
import ProductsCreate from "./pages/admin/Product/ProductsCreate";
import { useSelector } from "react-redux";
import Landing from "./pages/home/Index";
// import Error from "./pages/Error";
import ProductDetails from "./pages/user/ProductDetailPage/Detail";
import Error from "./pages/Error";
import ListingProduct from "./pages/user/listingPage/ListingProduct";
// import { Navbar } from "./component";

function App() {
  const {user} = useSelector((store)=>store.user)
  return (
    <>
   <Routes>
        {!user ? <Route exact path="/user/productlist" element={<Landing />}>
        <Route exact path='/user/productlist/pageNo=:pageNo' element={ <Landing />}> </Route>        </Route> :
        <Route>
       <Route index element={<Landing/>}/>

     
        <Route path="/" element={<ProtectedRoutes><ShareLayout /></ProtectedRoutes>}>
        {/* <Route index element={<Products/>}/> */}
          <Route path="/admin/category" element={<Categories />} />
          <Route path='/admin/category/create' element={<CreateCategories />} />
          <Route path='/admin/category/edit/:id' element={<EditCategory />} />
          <Route path="/admin/product" element={<Products />} />
          <Route path='/admin/create-product' element={<ProductsCreate />} />
          <Route path='/admin/product/edit/:id' element={<ProductEdit />} />
        </Route>
        </Route>}
      <Route path="/admin" element={<Login />}></Route>
      <Route exact path="*" element={<Error/>}></Route>
    <Route path = "/" element={<Landing/>}/>
        <Route exact path="/user/productdetail/:id" element={<ProductDetails />}></Route>
        <Route path="/user/categorylist/products/:id" element={<ListingProduct/>}/>
        
      {/* <Route path="/" element={<Login/>}></Route> */}
      </Routes>
      </>
    //  <Login/>  
  );
}

export default App;
