/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";
import CreateProductForm from "../../../component/CreateProductForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSingleProduct,
  updateProduct,
  updateProductDraft,
} from "../../../features/products/productSlice";
import ButtonDrop from "../../../component/button";
import { toast } from "react-toastify";
import Wrapper from "../../../assets/wrappers/ProductFrom";

const ProductEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productId = useParams();

  const { singleProductData, products, singleProductLoading } = useSelector(
    (store) => store.product
  );

  // const localData = JSON.parse(localStorage.getItem("Values"));

  const serverMainImageUrl =
    "https://chapshop.softprodigyphp.in/uploads/" +
    `${
      singleProductData &&
      singleProductData.main_image &&
      singleProductData.main_image[0]
        ? singleProductData.main_image[0].filename
        : ""
    }`;
  // singleProductData.main_image[0].filename;
  const serverShareImagesurl = [];

  for (let i = 0; i < singleProductData?.sharing_images?.length; i++) {
    serverShareImagesurl.push(
      "https://chapshop.softprodigyphp.in/uploads/" +
        `${
          singleProductData &&
          singleProductData.sharing_images &&
          singleProductData.sharing_images[i]
            ? singleProductData.sharing_images[i].filename
            : ""
        }`
      //  singleProductData.sharing_images[i].filename
    );
  }

  const [multipleImage, setMultipleImage] = useState([]);
  const [multipleImgeUrl, setMultipleImageUrl] = useState([]);
  let validationError = false;

  const [finputValues, fsetInputValues] = useState({
    productName: "",
    BuyingPrice: "",
    ResellingPrice: "",
    sku: "",
    mainImage: [],
    SharingImage: null,
    category_id:"",
    category_name:""
  });
  const [checkSize, setCheckSize] = useState([]);

  const checkHandler = (e) => {
    const { value, checked } = e.target;
    if (checked) setCheckSize([...checkSize, value]);
    else {
      setCheckSize(checkSize.filter((e) => e !== value));
    }
  };

  const arr = finputValues?.productName?.split(" ");

  for (var i = 0; i < arr?.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase().trim() + arr[i].slice(1).trim();
  }
  const finalProductName = arr?.join(" ").trim();


  const productNameRegex = /^[a-z A-Z 0-9 á-ú Á-Ú à-ù â-û À-Ù Ä-Ü çëüÿñæœæœœ ' & .-]*$/;
  const notOnlyNumRegex = /(?!^\d+$)^.+$/i;
  const buyingPriceNum = Number(finputValues.BuyingPrice);
  const resellingPriceNum = Number(finputValues.ResellingPrice);

  useEffect(() => {
    !singleProductLoading &&
      fsetInputValues({
        ...finputValues,
        productName: singleProductData?.product_name,
        BuyingPrice: singleProductData?.buying_price,
        ResellingPrice: singleProductData?.reselling_price,
        sku: singleProductData?.sku,
        category_id: singleProductData?.category?._id,
        category_name:singleProductData?.category?.category_name

      });
  }, [singleProductData, singleProductLoading]);
  
  useEffect(() => {
    !singleProductLoading && setCheckSize(singleProductData.sizes);
  }, [singleProductData, singleProductLoading]);

  const [selectedOption, setSelectedOption] = useState(null);
  const { categories } = useSelector((store) => store.category);

  const SelectionArray = [];
  categories.forEach((item) =>
    SelectionArray.push({
      value: item.category_name,
      label: item.category_name,
      id: item._id,
    })
  );

  useEffect(() => {
    dispatch(getSingleProduct(productId.id));
  }, []);

  const prod = products.filter((fill) => {
    return fill.id === productId.id;
  });

  const handleBack = () => {
    // localStorage.removeItem("Values");
    setSelectImage("");
    navigate("/admin/product");
  };

  const productInputHandler = (e) => {
    const { name, value } = e.target;
    fsetInputValues({ ...finputValues, [name]: value });
  };

  const validatorFunction = () => {
    if (finputValues.productName.trim() === "") {
      validationError = true;
      return toast.warning("Enter product name");
    }
    if (finputValues.productName.trim().length < 3) {
      validationError=true
      return toast.warning("Product name should contain minimum 3 character.");
    }
    if (!productNameRegex.test(finputValues.productName)) {
      validationError = true;
      return toast.warning("This is not a valid product name.");
    }
    if (!notOnlyNumRegex.test(finputValues.productName)) {
      validationError = true;
      return toast.warning("Only numbers are not allowed as product name");
    }
    if (finputValues.sku < 3) {
      validationError = true;
      return toast.warning("SKU should contain minimum 3 characters");
    }
    if (!productNameRegex.test(finputValues.sku)) {
      validationError = true;
      return toast.warning("This is not a valid SKU.");
    }
    if (finputValues.BuyingPrice === "") {
      validationError = true;
      return toast.warning("Enter buying price");
    }
    if (buyingPriceNum <= 0) {
      validationError = true;
      return toast.warn("Negative or zero buying price is not allowed.");
    }
    if (finputValues.ResellingPrice === "") {
      validationError = true;
      return toast.warning("Enter reselling price.");
    }
    if (resellingPriceNum <= 0) {
      validationError = true;
      return toast.warning("Negative or zero reselling price is not allowed.");
    }
    if (resellingPriceNum < buyingPriceNum) {
      validationError = true;
      return toast.warning(
        "Reselling price should not be less than buying price."
      );
    }
  };

  const handleSubmit = () => {
    validatorFunction();
    if (validationError) {
      return;
    }

    dispatch(
      updateProduct({
        product_name: finalProductName,
        buying_price: finputValues.BuyingPrice,
        reselling_price: finputValues.ResellingPrice,
        category_id: finputValues.category_id,
        sku: finputValues.sku,
        product_id: productId.id,
        mainImage: selectImage,
        sharingImages: multipleImage,
        sizes: checkSize,
        is_draft: false,
      })
    );
    navigate("/admin/product");
  };

  const handleDraft = () => {
    validatorFunction();
    if (validationError) {
      return;
    }
    dispatch( 
      updateProductDraft({
        product_name: finalProductName,
        buying_price: finputValues.BuyingPrice,
        reselling_price: finputValues.ResellingPrice,
        category_id: finputValues.category_id,
        product_id: productId.id,
        sku: finputValues.sku,
        mainImage: selectImage,
        sharingImages: multipleImage,
        sizes: checkSize,
        is_draft: true,
      })
    );
    navigate("/admin/product");
  };

  const [selectImage, setSelectImage] = useState();
  const [imageURL, setImageURL] = useState();

  const mainInputImageHandler = (e) => {
    const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      toast.warning("Invalid image type, only .jpeg/.jpg/.png are allowed");
      return setFile(file);
    }
    const test = Object.values(e.target.files);
    let error = false;
    test.forEach((item) => {
      if (item.size > 2000000) {
        error = true;
        setSelectImage([]);
        return toast.warn("Image size should be less than 2 mb.");
      }
    });
    if (error === true) {
      return;
    }
    setSelectImage(...e.target.files);
    setImageURL(URL.createObjectURL(...e.target.files));
  };

  function handleChange(e) {
    const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      toast.warning("Invalid image type, only .jpeg/.jpg/.png are allowed");
      return setFile(file);
    }
    if (e.target.files.length > 5) {
      return toast.warn("More than 5 images are not allowed.");
    }
    const test = Object.values(e.target.files);
    let error = false;
    test.forEach((item) => {
      if (item.size > 2000000) {
        error = true;
        setMultipleImage([]);
        return toast.warn("Image size should be less than 2 mb.");
      }
    });
    if (error === true) {
      return;
    }
    setMultipleImage([...e.target.files]);
    setMultipleImageUrl([]);
    for (let i = 0; i < e.target.files.length; i++) {
      const newImageURL = URL.createObjectURL(e.target.files[i]);
      setMultipleImageUrl((prevState) => [...prevState, newImageURL]);
    }
  }

  const output = singleProductData?.category?.sizes.filter(function (obj) {
    return singleProductData.sizes.indexOf(obj) === -1;
  });

  const [file, setFile] = useState(null);

  return (
    <Wrapper>
      {singleProductLoading ? (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          {" "}
          <h2 style={{ textAlign: "center" }}>Update Product</h2>
          <hr />
          <br />
          <div className="form-card">
            <div className="form-box">
              <div className="select-line">
                <label>Category</label>
                <Select
                  disabled
                  defaultValue={{
                    label:
                      singleProductData && singleProductData
                        ? singleProductData.category?.category_name
                        : selectedOption,
                  }}
                  onChange={setSelectedOption}
                  placeholder="Select for category specific products"
                  isClearable
                  options={SelectionArray}
                  isDisabled
                />
              </div>

              {singleProductLoading ? (
                <p>Loading..</p>
              ) : (
                <div className="main-product-input">
                  <CreateProductForm
                    onProductInputHandler={productInputHandler}
                    prodName={singleProductData.product_name}
                    buyingPrice={singleProductData.buying_price}
                    resellingPrice={singleProductData.reselling_price}
                    sku={singleProductData.sku}
                    imp={true}
                  />
                  <span>Select Sizes </span>{" "}
                  {/* <span className="g-asterisk">*</span> */}
                  <div className="size-selector">
                    {singleProductData?.sizes?.map((item, index) => {
                      return (
                        <div className="size-label" key={index}>
                          {" "}
                          <label htmlFor={item}>
                            <input
                              id={item}
                              defaultChecked={true}
                              value={item}
                              type="checkbox"
                              onChange={(e) => checkHandler(e)}
                            />
                            {item}
                          </label>
                        </div>
                      );
                    })}
                    {output?.map((item, index) => {
                      return (
                        <div className="size-label" key={index}>
                          {" "}
                          <label htmlFor={item}>
                            <input
                              id={item}
                              value={item}
                              type="checkbox"
                              onChange={(e) => checkHandler(e)}
                            />
                            {item}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="uploadmain-container">
                    <div className="uploadImage">
                      <div className="image-option">
                        <span>Main Image</span>{" "}
                        <span className="g-asterisk">*</span>
                        <em className="tooltip-ico">
                          i{" "}
                          <span className="tooltiptext">
                            Accepted image size : 2MB
                          </span>
                        </em>
                        <input
                          type="file"
                          id="main"
                          className="main-image"
                          accept=".png, .jpg, .jpeg, .webp"
                          onChange={(e) => {
                            mainInputImageHandler(e);
                          }}
                        />
                        <label htmlFor="main" className="sharedImage-label">
                          Choose File
                        </label>
                      </div>
                      <div>
                        {imageURL ? (
                          <img
                            className="show-image me-2"
                            src={imageURL}
                            alt="main"
                          />
                        ) : (
                          <img
                            className="show-image me-2"
                            src={serverMainImageUrl}
                            alt="main"
                          />
                        )}
                      </div>
                    </div>
                    <div className="uploadImage">
                      <div className="image-option">
                        <span>Sharing Images</span>{" "}
                        <span className="g-asterisk">*</span>
                        <em className="tooltip-ico">
                          i{" "}
                          <span className="tooltiptext">
                            Accepted image size : 2MB
                          </span>
                        </em>
                        <input
                          type="file"
                          className="shared"
                          id="sharingImageInput"
                          multiple
                          accept=".png, .jpg, .jpeg, .webp"
                          onChange={(e) => handleChange(e)}
                        />
                        <label
                          htmlFor="sharingImageInput"
                          className="sharedImage-label"
                        >
                          Choose File
                        </label>
                      </div>
                      <div>
                        {multipleImgeUrl.length > 0
                          ? multipleImgeUrl.map((item, index) => {
                              return (
                                <img
                                  className="show-image"
                                  src={item}
                                  key={index}
                                  alt="singleProductData?.product_name"
                                />
                              );
                            })
                          : serverShareImagesurl.map((item, index) => {
                              return (
                                <img
                                  className="show-image me-2"
                                  src={item}
                                  key={index}
                                  alt="singleProductData?.product_name"
                                />
                              );
                            })}
                      </div>
                    </div>
                  </div>
                  <div className="catg-title">
                    <button
                      className="g-btn"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      Retour
                    </button>
                    <ButtonDrop
                      handleSubmit={handleSubmit}
                      handleDraft={handleDraft}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default ProductEdit;
