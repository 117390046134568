import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import customFetch from "../../utils/axios";
import { addUserToLocalStorage, getUserFromLocalStorage, removeUserFromLocalStorage } from "../../utils/localStorage";
import customAxios from "../../utils/axios";
const initialState ={
    // adminUser:true,
    Nuser:'testUser',

    userRole:'',
    isLoading:false,
    isSidebarOpen: false,
    user:getUserFromLocalStorage()
};


export const loginUser = createAsyncThunk(
    'user/loginUser', async(user, thunkAPI)=>{
       try{
          const response = await customAxios.post("/user/login", user)
         
          return response.data
       }
       catch(e){
        return thunkAPI.rejectWithValue(e.response.data.message);

       }
    }
)

const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        toggleSidebar:(state)=>{
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        logOutUser:(state, thunkAPI)=>{
            state.user=null
            toast.success('Logout successfully.')
            removeUserFromLocalStorage()
            state.userRole=''
        },
        
    },
    extraReducers:{
       
        [loginUser.pending]:(state)=>{
            state.isLoading = true
        },
        [loginUser.fulfilled]:(state,{payload})=>{
            const user = payload.data.user
            state.user =user
            addUserToLocalStorage(user)
            state.userRole=user.userType
            toast.success(`Welcome back ${user.firstName}.`)

        },
        [loginUser.rejected]:(state, {payload})=>{
            state.isLoading=false
            toast.error(payload)
        }
    }
})

export const {toggleSidebar, logOutUser}=userSlice.actions;
export default userSlice.reducer;