import React, { useState } from 'react'
import styled from 'styled-components'
const Sorting = (props) => {
const handleChange = (e) => {
props.handleSort(e.target.value)
}
    return (
        <>
            <Wrapper>
                <select  className="rounded"
                    onChange={(e) => { handleChange(e) }}>
                    <option value=""> Sort by</option>
                    <option disabled>Re-selling Price</option>
                    <option value="ace">Price: Low to High</option>
                    <option value="des" >Price: High to Low</option>
                </select>
            </Wrapper>
        </>
    )
}

export default Sorting


const Wrapper = styled.div`
select{

    height:2.5rem;
    border:1px solid #e0e0e0;
    width:48%;
    padding:5px;
}
@media (max-width: 768px) {
    select{
        width: 100%;
        
    }
}
@media (max-width: 575px) {
    select{
        width: 100%;
        
    }
}

`