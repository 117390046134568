import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import authHeader from "../../utils/authHeader";
// import authHeaderUser from "../../utils/authHeaderUser";
import customAxios from "../../utils/axios";

const initialState = {
  isLoading: false,
  categoryFilter: false,
  getLoading: false,
  getSingleLoading:false,
  createLoading: false,
  deleteLoading: false,
  updateLoading: false,
  userCategoryLoading:false,
  categories: [],
  singleCategory:[],
  isSidebarOpen: false,
  adminUser: true,
  editCategoryData: {
    sizes: [],
    _id: "",
    category_name: "",
  },
 };


 export const createCategoryList = createAsyncThunk(
  "categories/creation",
  async (data, thunkAPI) => {
    let fData = new FormData();
    fData.append("category_name", data.finalCategoryName);
    data.sizes.forEach(item => fData.append("sizes[]", item));
    fData.append("category_image", data.selectImage[0]);
    try {
      const response = await customAxios.post(
        "/category/addCategory",
        fData,
        authHeader(thunkAPI)
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.msg);
    }
  }
);

//data.mainImage && fData.append("main_image", data.mainImage);
export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  
  async (data, thunkAPI) => {
    let fData = new FormData();
    fData.append("category_name", data.finalCategoryName);
    fData.append("category_id", data.editId)
    data.sizes.forEach(item => fData.append("sizes[]", item));
    // fData.append("category_image", data.selectImage[0]);
    data.selectImage.length>=1 && fData.append("category_image", data.selectImage[0]);
    try {
      const response = await customAxios.put(
        `/category/updateCategory`,
        fData,
        authHeader(thunkAPI)
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const removeCategory = createAsyncThunk(
  "categories/removeCategory",
  async (data, thunkAPI) => {
    try {
      const response = await customAxios.delete(
        `/category/deleteCategory/${data}`,
        authHeader(thunkAPI)
      );
      thunkAPI.dispatch(deleteCategory(data));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);
export const getCategory = createAsyncThunk(
  "categories/getCategory",
  async (_, thunkAPI) => {
    try {
      const response = await customAxios.get(
        "/category/categoryList",
        authHeader(thunkAPI)
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.msg);
    }
  }
);


export const getSingleCategory = createAsyncThunk(
  "category/getCategory",
  async (data, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `/category/getCategory/${data}`,
        authHeader(thunkAPI)
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.msg);
    }
  }
);




export const userCategoryList = createAsyncThunk(
  "user/categoryList",
  async (_, thunkAPI) => {
    try {
      const response = await customAxios.get(
        "/user/categoryList",
        // authHeaderUser()
      );
      return response.data;
    }
    catch (e)
    {
      return (e.response.data.msg);
    }
  }
);

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    createCategory: (state, { payload: { name, sizes } }) => {
      // if(state.categories.some(e=>e.name===name)){
      //    return toast.error("item already exists")
      // //    return alert("item already exists")

      // }
      state.categories.push({ name: name, sizes: sizes });
      toast.success("Category created");
    },

    deleteCategory: (state, { payload }) => {
      if (state.categories.length === 1) {
        state.categories = [];
      }
      // state.categories = state.categories.filter(
      //   (item) => item._id !== payload
      // );
    },
    editCategory: (state, { payload }) => {
      state.editCategoryData.sizes = payload.sizes;
      state.editCategoryData.category_name = payload.category_name;
      state.editCategoryData._id = payload._id;
      // state.editCategoryData.
      // sizes: ['d', 'df'],
      // _id: '',
      // category_name: 'dd'
    },
    changeFilterState: (state) => {
      state.categoryFilter = !state.categoryFilter;
    },
  },
  extraReducers: {
    [createCategoryList.pending]: (state) => {
      state.createLoading = true;
    },
    [createCategoryList.fulfilled]: (state, action) => {
      state.createLoading = false;

      toast.success("Category created");
    },
    [createCategoryList.rejected]: (state, { payload }) => {
      state.createLoading = false;
      toast.error(payload);
    },
    [getCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getCategory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.categories = payload.data;
      // state.categories = payload.data?.sort((a, b) => a.category_name.localeCompare(b.category_name));

    },
    [getCategory.rejected]: (state, { payload }) => {
      state.isLoading = false;
     toast.error(payload);
    },
    [getSingleCategory.pending]: (state) => {
      state.getSingleLoading = true;
    },
    [getSingleCategory.fulfilled]: (state, { payload }) => {
      state.getSingleLoading = false;
      state.singleCategory = payload.data;
    },
    [getSingleCategory.rejected]: (state, { payload }) => {
      state.getSingleLoading = false;

      toast.error(payload);
    },

    [removeCategory.pending]: (state) => {
      state.deleteLoading = true;
    },
    [removeCategory.fulfilled]: (state, { payload }) => {
      state.deleteLoading = false;
      toast.success(payload.data);
    },
    [removeCategory.rejected]: (state, { payload }) => {
      state.deleteLoading = false;
      toast.error(payload);
    },
    [updateCategory.pending]: (state) => {
      state.updateLoading = true;
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      state.updateLoading = false;
      state.singleCategory=[]
      toast.success(payload.data);
      // state.categories = payload.data;
    },
    [updateCategory.rejected]: (state, { payload }) => {
      state.updateLoading = false;
      toast.error(payload);
    },

   [userCategoryList.pending]: (state) => {
      state.userCategoryLoading = true;
    },
    [userCategoryList.fulfilled]: (state, { payload }) => {
      state.userCategoryLoading = false;
     state.categories = payload.data?.sort((a, b) => a.category_name.localeCompare(b.category_name));
    },
    [userCategoryList.rejected]: (state, { payload }) => {
      state.userCategoryLoading = false;
      toast.error(payload);
    },

  },
});

export const {
  createCategory,
  deleteCategory,
  editCategory,
  changeFilterState,
} = categorySlice.actions;
export default categorySlice.reducer;
