import { IoBarChartSharp } from 'react-icons/io5';
import { MdQueryStats } from 'react-icons/md';
// import { FaWpforms } from 'react-icons/fa';
// import { ImProfile } from 'react-icons/im';


const links = [
    {
      id: 1,
      text: 'categories',
      path: '/admin/category',
      icon: <IoBarChartSharp />,
    },
    {
      id: 2,
      text: 'products',
      path: '/admin/product',
      // path: '/product-create',
      icon: <MdQueryStats />,
    },
    




    // {
    //   id: 3,
    //   text: 'boxes',
    //   path: '/admin/boxes',
    //   icon: <FaWpforms />,
    // },
    // {
    //   id: 4,
    //   text: 'profile',
    //   path: '/profile',
    //   icon: <ImProfile />,
    // },
  ];
  
  export default links;