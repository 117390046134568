import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSingleCategory, updateCategory } from '../../../features/categories/categorySlice';
import { MdOutlineCancel } from 'react-icons/md';
import Wrapper from '../../../assets/wrappers/CreateCategories'

function EditCategory() {
  const{singleCategory, getSingleLoading,updateLoading} = useSelector((store)=>store.category)
  let editId = singleCategory._id;
  const navigate = useNavigate()

  const dispatch = useDispatch()
  
  const [name, setName] = useState("");
  const [sizeInput, setSizeInput] = useState("");
  const [sizes, setSizes] = useState([]);
  const [selectImage, setSelectImage] = useState([]);

  const [imageURL, setImageURL] = useState();

  const [file, setFile] = useState([]);

  
  const categoryNameRegex =  /^[a-z A-Z 0-9 á-ú Á-Ú à-ù â-û À-Ù Ä-Ü çëüÿñæœæœœ ' & .-]*$/;
  const notOnlyNumRegex = /(?!^\d+$)^.+$/i


  useEffect(
    ()=>{ setName( singleCategory && singleCategory.category_name ? singleCategory.category_name :"");
     setSizes(singleCategory && singleCategory.sizes? singleCategory.sizes:[])} ,[getSingleLoading]
    
  )

  const serverMainImageUrl =


  "https://chapshop.softprodigyphp.in/uploads/" +
  `${
    singleCategory &&
    singleCategory.category_image &&
    singleCategory.category_image
      ? singleCategory.category_image.filename
      : ""
  }`;
 
  const finalCategoryName = name.charAt(0).toUpperCase().trim() + name.slice(1).trim()

    const categoryId = useParams()
    useEffect(()=>{
      dispatch(getSingleCategory(categoryId.id))
    },[])
 
  const sizeHandler = (e) => {
    e.preventDefault();
    if (sizeInput.length <1) {
      return toast.warning("Please add size first.");
    }
    if (!categoryNameRegex.test(sizeInput)) {
      return toast.warning("This is not a valid size.") ;
    }
    if(sizes.includes(sizeInput)){
      return toast.warn("Size already exists.")
    } 
    if(sizes.length===50){
      return toast.warning("You can input only 50 sizes")
    }
    setSizes((prevCount) => [...prevCount, sizeInput]);
    setSizeInput("");
  };
  const createSizeDeleteHandler = ({index})=>{
    setSizes(sizes.filter((value ,valueIndex)=>valueIndex!==index )) 
   }
  //  const filteredCategory = categories.filter((item)=>item._id !==singleCategory._id) 
   
  const submitHandler = (e) => {
    e.preventDefault()
    
    // if (filteredCategory.some((e) => e.category_name.toLowerCase() === name.toLowerCase())) {
    //   return toast.error("Category Name already exists.");
    // }
   if(name.length===0&&sizes.length===0){
    return toast.warning("Please add category and size.")
   }
   if(name.length===0){
    return toast.warning("Please add category name first.")
   }
   if(name.length<=2){
    return toast.warning("Category name should contain atleast three letters.")
  }
   if (!categoryNameRegex.test(name)) {
    return toast.warning("This is not a valid category name.") ;
  }
  if(!notOnlyNumRegex.test(name)){
    return toast.warning("Only numbers are not allowed as category name")
  }
   if(sizes.length===0){
    return toast.warning('Please add size first.')
   }
   dispatch(updateCategory({finalCategoryName, sizes, selectImage, editId
  }))
  if(!updateLoading){
    navigate("/admin/category")
  };

}

const mainImageHandler = e => {
  const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
  const file = e.target.files[0];
  if (!file?.type.match(imageMimeType)) {
    toast.warning("Invalid image type, only .jpeg/.jpg/.png are allowed");
    return setFile([]);
  }
  const test = Object.values(e.target.files);
  let error = false;
  test.forEach(item => {
    if (item.size > 2000000) {
      error = true;
      setSelectImage([]);
      return toast.warn("Image size should be less than 2 mb.");
    }
  });
  if (error === true) {
    return;
  }
  setSelectImage([...e.target.files]);
  setImageURL(URL.createObjectURL(e.target.files[0]));
};


  return (
    <Wrapper>


{getSingleLoading? <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>:<>    <div className="global-title"> Edit Categories</div>
    {!getSingleLoading && singleCategory.category_name ?
    <form >
      <div className="form-row">
        <div className="cat-fields">
        <div className="form-headings">
          <label htmlFor="size">Category</label><span className="g-asterisk">*</span>
          </div>
          <input
            className="cat-input"
            type="text"
            id="category"
            maxLength="50"
            name="categoryName"
            value={name}
            // defaultValue={singleCategory && singleCategory.category_name ? singleCategory.category_name :""}
            placeholder="Enter new category"
            onChange={(e) => setName(e.target.value)}
          />
           <div className="categoryUploadImage">
              <div className="image-option"> 
                <span>Category Image </span>
                <label className="g-asterisk">*</label>
                <em className="tooltip-ico">
                  i{" "}
                  <span className="tooltiptext">Accepted image size : 2MB</span>
                </em>
                <input
                  type="file"
                  id="main"
                  accept=".png, .jpg, .jpeg, .webp"
                  className="main-image"
                  onChange={e => mainImageHandler(e)}
                />
                <label htmlFor="main" className="sharedImage-label">
                  Choose File
                </label>
              </div>

                <div>
                {imageURL ? (
                          <img
                            className="show-image me-2"
                            src={imageURL}
                            alt="main"
                          />
                        ) : (
                          <img
                            className="show-image me-2"
                            src={serverMainImageUrl}
                            alt="main"
                          />
                        )}
                </div>
              </div>

          <div>
          <button
              className="c-btn"             
              type="text"
              onClick={()=>navigate("/admin/category")}
            >
              Back{" "}
            </button>
           {"  "}
          <button
            className="c-btn"
            onClick={submitHandler}
            type='text'
          >
            Submit{" "}
          </button> 
          </div>
           
        </div>

        <div className="cat-fields">
        <div className="form-headings">
          <label htmlFor="size">Size</label><span className="g-asterisk">*</span>
          </div>
          <div className="size-input-form">
            <input
              className="size-input"
              type="text"
              id="size"
              maxLength="15"
              placeholder="Enter size"
              value={sizeInput}
              onChange={(e) => setSizeInput(e.target.value.toUpperCase())}
            />
            <button className="size-btn" type="submit"  onClick={sizeHandler}>

              Add size
              </button>
          </div>
          <div className="size-tags-list">
          {sizes.map((item, index) => {
            return (
              <div key={index} className="size-list">
                <div >{item}</div>
                <MdOutlineCancel className="delete-size" onClick={()=>createSizeDeleteHandler({index})}/>

              </div>
            );
          })}
          </div>
        </div>
      </div>
    </form> :  <p>Loading...</p>
}

    <div></div></>}

  </Wrapper>
  )
}

export default EditCategory


