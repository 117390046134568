import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

const CategoryListing = ({ category, isLoading }) => {
  const navigate = useNavigate();

  const handleSingleProduct = props => {
    navigate(`/user/categorylist/products/${props}`);
  };

  return (
    <Wrapper>
      {isLoading ? (
        <div className="d-flex m-5 spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className="row">
          {category &&
            category?.map(item => {
              return (
                <div
                  key={item._id}
                  className="col-md-3 col-12 mt-2"
                  onClick={e => {
                    handleSingleProduct(item?._id);
                  }}
                >
                  <div
                    className="card custom-card d-flex justify-content-center align-items-center"
                    role="button"
                  >
                    {item && item?.category_image && item.category_image ? (
                      <img
                        className="custom-card-img rounded-1"
                        src={
                          "https://chapshop.softprodigyphp.in/uploads/" +
                          `${
                            item &&
                            item?.category_image &&
                            item.category_image?.filename &&
                            item.category_image.filename
                          }`
                        }
                        alt={item?.product_name}
                      />
                    ) : (
                      <img
                        className="custom-card-img custom-card-img rounded-1"
                        src="https://media.istockphoto.com/id/1313650323/photo/digital-camera.jpg?s=170667a&w=0&k=20&c=OLtOr92mNtv2EkNmwxMV3tprEfel1Di-1U4X9hZhkEo="
                        alt={item?.product_name}
                      />
                    )}
                    <div className="d-flex justify-content-center flex-nowrap">
                      {item?.category_name.trim().length >= 20 ? (
                        <h5 className="card-title mt-2  overflow-wrap: text-wrap;">
                          {item?.category_name.substring(0, 20)}...
                        </h5>
                      ) : (
                        <h5 className="card-title mt-2  overflow-wrap: text-wrap;">
                          {item?.category_name}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </Wrapper>
  );
};

export default CategoryListing;

const Wrapper = styled.div`
  .product-img {
    width: 100%;
    height: 200px;
  }
  .custom-card {
    background: #fbfbfb;
    height: 95% ;
    //  width: 250px;
    margin-bottom: 17px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding-top: 0 !important;
    border-radius: 0.3rem;
    &:hover {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 2px 9px rgb(0 0 0 / 24%);
    }
  }
  .custom-card .card-title {
    letter-spacing: 0.1px;
  }
  .custom-card-img {
    object-fit: scale-down;
    width: 100%;
    max-height: 360px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;