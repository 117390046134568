import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getProduct } from "../../../features/products/productSlice";
import { getCategory } from "../../../features/categories/categorySlice";
import ProductTable from "../../../component/table/ProductTable";
import { useNavigate } from "react-router-dom";

function Products() {
  const dispatch = useDispatch();
  const { categories } = useSelector(store => store.category);
  const {
    updateProductLoading,
    updateProductDraftLoading,
    createProductDraftLoading,
    createProductLoading,
    getProductLoading,
  } = useSelector(store => store.product);
  const { products } = useSelector(store => store.product);

  const navigate = useNavigate();

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getProduct());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createProductLoading,
    createProductDraftLoading,
    updateProductLoading,
    updateProductDraftLoading,
  ]);

  const bolToStringProduct = products?.map(item => {
    if (item.is_draft) {
      return { ...item, is_draft: "No" };
    } else {
      return { ...item, is_draft: "Yes" };
    }
    // return {...item,   is_draft:"sdf"}
  });
 
  const [selectedOption, setSelectedOption] = useState(null);


const arraySorted = [];
  categories.map(item => arraySorted.push([item.category_name, item._id]));
  const sortedCategory = arraySorted?.sort();
  const SelectionArray = [];
  sortedCategory.forEach(item =>
    SelectionArray.push({
      value: item[0],
      label: item[0],
      id: item[1],
    })
  );

  return (
    <Wrapper>


    {createProductLoading || createProductDraftLoading?<div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>:<> <div className="catg-title">
        <h4>Products</h4>
        <button
          className="g-btn"
          onClick={() => {
            navigate("/admin/create-product");
            localStorage.removeItem("Values");
          }}
        >
          create new
        </button>
      </div>
      <div className="select-line">
        <div className="catg-select">
          <Select
            // defaultValue={selectedOption}
            onChange={setSelectedOption}
            placeholder="Select category"
            isClearable
            options={SelectionArray}
          />
        </div>
      </div>
      {getProductLoading ? <div className="container mt-5 d-flex justify-content-center">
          <div className="spinner-border " role="status">
  <span className="visually-hidden">Loading...</span>
</div>
        </div> : <ProductTable
        productList={bolToStringProduct}
        selectedCategory={selectedOption?.value}
      />}
     
      {/*  */}</>  }
     
    </Wrapper>
  );
}

export default Products;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .select-line {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    /* height:30px; */
  }
  .catg-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
  }
  .catg-select {
    width: 400px;
    display: inline-block;
    position: absolute;

    z-index: 1;
    /* margin-top:5px; */
  }
`;
