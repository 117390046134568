import React,{useEffect} from "react";
import Wrapper from "../../../assets/wrappers/LoginPage";
import Logo from "../../../component/Logo";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; 
import {useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../../features/user/userSlice";
import { useNavigate } from "react-router-dom";


const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).max(32).required(),
});

function Login() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const {  user } = useSelector((store) => store.user);

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = (data) => {

    dispatch(loginUser({...data}))
    reset();
  };


  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/admin/category");
      });
    }
  }, [user, navigate]);

  // const inputError = errors.password?.message
  const upercaseInputError  = errors.email?.message.charAt(0).toUpperCase()+errors.email?.message.slice(1)+'.'
  const uperCasePasswordError = errors.password?.message.charAt(0).toUpperCase()+errors.password?.message.slice(1)+'.'
  return (
    <Wrapper className="full-page">

    <form onSubmit={handleSubmit(onSubmitHandler)} className="form" >
    <Logo/>
    <h3>Login</h3>
      <div className="form-row">
      <div className="form-headings">

        <label htmlFor="email" className="form-label">
          User Email
        </label><span className="g-asterisk">*</span>
      </div>
        <input
          type="email"
          className="form-input"
          name="email"
          placeholder="Enter user email here."
          {...register("email")}
          required
        />
        <p className="form-warn"> { errors.email&&upercaseInputError} </p>
      </div>

      <div className="form-row">
      <div className="form-headings">
        <label htmlFor="password" className="form-label">
          Password
        </label><span className="g-asterisk">*</span>
        </div>
        <input
          type="password"
          className="form-input"
          name='password'
          placeholder="Enter user password here."
          {...register("password")}
          required
        />
        
        <p className="form-warn"> {errors.password && uperCasePasswordError} </p>
      </div>
      <button type="submit" className="g-btn g-btn-block"> Log in</button>
      </form>
    </Wrapper>
  );
}

export default Login;
