import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin: 1rem;
  border-radius:20px;
  padding:1rem;
    color:black;
    background: var(--white);
    min-height:80vh;
    min-width:300px;  */

.catg-title{
    display:flex;
    justify-content:space-between;
    width:100%;
}
.catg-list{
    /* background-color:#bfdbfe; */
   display: flex;
   flex-direction:row;
    align-items:center;
    margin-top:10px;}
.catg-name{
    min-width:300px;
    max-width:400px;
    padding: 10px;
    background-color:#bfdbfe;
    min-height:50px;
    word-wrap:break-word;
    border-radius:10px
}

  
`
export default Wrapper



