import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import authHeader from "../../utils/authHeader";
// import authHeaderUser from "../../utils/authHeaderUser";
import customAxios from "../../utils/axios";

const initialState = {
  isLoading: false,
  //   categoryFilter: false,
  getProductLoading: false,
  createProductLoading: false,
  createProductDraftLoading: false,
  updateProductLoading: false,
  updateProductDraftLoading: false,
  deleteProductLoading: false,
  singleProductLoading: false,
  userProductLoading:false,
  userSingleProductLoading:false,
  singleProductData: [],
  products: [],
  singleProduct: {},
  isSidebarOpen: false,
  userCategoryProductLoading: false,
  pageNoBackend: "",
  catReduxPage: "",
};

export const getProduct = createAsyncThunk(
  "products/getProduct",
  async (_, thunkAPI) => {
    try {
      const response = await customAxios.get(
        "/product/productList",
        authHeader(thunkAPI)
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.msg);
    }
  }
);

export const getSingleProduct = createAsyncThunk(
  "products/getSingleProduct",
  async (data, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `/product/getProduct/${data}`,

        authHeader(thunkAPI)
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.msg);
    }
  }
);

export const createProduct = createAsyncThunk(
  "products/creation",
  async (data, thunkAPI) => {
    let fData = new FormData();
    fData.append("product_name", data.product_name);
    fData.append("buying_price", data.buying_price);
    fData.append("reselling_price", data.reselling_price);
    fData.append("sku", data.sku);
    fData.append("category_id", data.category_id);
    fData.append("mainImage", data.mainImage[0]);
    fData.append("is_draft", false);
    data.sizes.forEach(item => fData.append("sizes[]", item));
    data.sharingImages.forEach(element => {
      return fData.append("sharingImages", element);
    });
    try {
      const response = await customAxios.post(
        "/product/addProduct",
        fData,
        authHeader(thunkAPI)
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const createProductDraft = createAsyncThunk(
  "products/creation?is_draft=true",
  async (data, thunkAPI) => {
    // const payload = {...data}
    let fData = new FormData();
    fData.append("product_name", data.product_name);
    fData.append("buying_price", data.buying_price);
    fData.append("reselling_price", data.reselling_price);
    fData.append("category_id", data.category_id);
    fData.append("mainImage", data.mainImage[0]);
    fData.append("sku", data.sku);
    fData.append("is_draft", true);
    data.sizes.forEach(item => fData.append("sizes[]", item));
    data.sharingImages.forEach(element => {
      return fData.append("sharingImages", element);
    });
    try {
      const response = await customAxios.post(
        "/product/addProduct",
        fData,
        authHeader(thunkAPI)
      );
      toast.success("Draft saved successfully");
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (data, thunkAPI) => {
    let fData = new FormData();
    fData.append("product_name", data.product_name);
    fData.append("buying_price", data.buying_price);
    fData.append("reselling_price", data.reselling_price);
    fData.append("sku", data.sku);
    fData.append("category_id", data.category_id);
    data.sizes.forEach(item => fData.append("sizes[]", item));
    data.mainImage && fData.append("main_image", data.mainImage);
    data.sharingImages &&
      data.sharingImages.forEach(element => {
        return fData.append("sharing_images", element);
      });
    fData.append("product_id", data.product_id);
    fData.append("is_draft", false);

    try {
      const response = await customAxios.put(
        `/product/updateProduct`,
        fData,
        authHeader(thunkAPI)
      );
      toast.success("Product Updated");
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateProductDraft = createAsyncThunk(
  "products/updateProduct?is_draft=true",
  async (data, thunkAPI) => {
    let fData = new FormData();
    fData.append("product_name", data.product_name);
    fData.append("buying_price", data.buying_price);
    fData.append("reselling_price", data.reselling_price);
    fData.append("sku", data.sku);
    fData.append("category_id", data.category_id);
    data.sizes.forEach(item => fData.append("sizes[]", item));
    data.mainImage && fData.append("main_image", data.mainImage);
    data.sharingImages &&
      data.sharingImages.forEach(element => {
        return fData.append("sharing_images", element);
      });
    fData.append("product_id", data.product_id);
    fData.append("is_draft", true);
    try {
      const response = await customAxios.put(
        `/product/updateProduct`,
        fData,
        authHeader(thunkAPI)
      );
      toast.success("Product draft updated ");
      return response.data;
    } catch (e) {
      return (
        thunkAPI.rejectWithValue(e.response.data.message),
        toast.warn("Invalid error")
      );
    }
  }
);

export const removeProduct = createAsyncThunk(
  "products/removeProduct",
  async (data, thunkAPI) => {
    try {
      const response = await customAxios.delete(
        `/product/deleteProduct/${data}`,
        authHeader(thunkAPI)
      );
      thunkAPI.dispatch(deleteProductLocal(data));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const userGetProduct = createAsyncThunk(
  "user/productList",
  async data => {
    try {
      const response = await customAxios.get(
        `/user/productList/${data}`
        // authHeaderUser()
      );
      return response.data;
    } catch (e) {
      return e.response.data.msg;
    }
  }
);

export const userSingleProduct = createAsyncThunk(
  "user/getProduct",
  async (data, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `/user/getProduct/${data}`
        //  authHeaderUser()
      );
      return response.data;
    } catch (e) {
      return e.response.data.message;
    }
  }
);

export const userCategoryProduct = createAsyncThunk(
  "user/categoryProducts",
  async (data, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `/user/categoryProducts/${data}`
        //     authHeaderUser()
      );
      return response.data;
    } catch (e) {
      return e.response.data.message;
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    createProductLocal: (state, { payload: { name, sizes } }) => {
      state.categories.push({ name: name, sizes: sizes });
      toast.success("Category created");
    },

    deleteProductLocal: (state, { payload }) => {
      state.products = state.products.filter(item => item._id !== payload);
    },
    editProductLocal: (state, { payload }) => {
      state.editCategoryData.sizes = payload.sizes;
      state.editCategoryData.category_name = payload.category_name;
      state.editCategoryData._id = payload._id;
    },
    clearSingleProductData: state => {
      state.singleProductData = [];
    },
    clearTotalPages: state => {
      state.catReduxPage = -1;
      state.pageNoBackend = -1;
    },
    clearProduct:(state)=>{
      state.products=[]
    },
    clearCategoryProducts: state => {
      state.products = [];
    },
  },
  extraReducers: {
    [getProduct.pending]: state => {
      state.getProductLoading = true;
    },
    [getProduct.fulfilled]: (state, { payload }) => {
      state.getProductLoading = false;
      state.products = payload?.data;
    },
    [getProduct.rejected]: (state, { payload }) => {
      state.getProductLoading = false;
      // toast.error(payload);
    },

    [getSingleProduct.pending]: state => {
      state.singleProductLoading = true;
    },
    [getSingleProduct.fulfilled]: (state, { payload }) => {
      state.singleProductLoading = false;
      state.singleProductData = payload?.data;
    },
    [getSingleProduct.rejected]: (state, { payload }) => {
      state.singleProductLoading = false;
      // toast.error(payload);
    },
    [createProduct.pending]: state => {
      state.createProductLoading = true;
    },
    [createProduct.fulfilled]: (state, action) => {
      state.createProductLoading = false;
      toast.success("Product added successfully");
    },
    [createProduct.rejected]: (state, { payload }) => {
      state.createProductLoading = false;
      toast.error(payload);

      // toast.error(payload);
    },
    [createProductDraft.pending]: state => {
      state.createProductDraftLoading = true;
    },
    [createProductDraft.fulfilled]: (state, action) => {
      state.createProductDraftLoading = false;
    },
    [createProductDraft.rejected]: (state, action) => {
      state.createProductDraftLoading = false;
      toast.error(action.payload);
    },
    [updateProduct.pending]: state => {
      state.updateProductLoading = true;
    },
    [updateProduct.fulfilled]: (state, { payload }) => {
      state.updateProductLoading = false;
    },
    [updateProduct.rejected]: (state, { payload }) => {
      state.updateProductLoading = false;
      toast.error(payload);
    },
    [updateProductDraft.pending]: state => {
      state.updateProductDraftLoading = true;
    },
    [updateProductDraft.fulfilled]: (state, { payload }) => {
      state.updateProductDraftLoading = false;
    },
    [updateProductDraft.rejected]: (state, { payload }) => {
      state.updateProductDraftLoading = false;
      toast.error(payload);
    },
    [removeProduct.pending]: state => {
      state.deleteProductLoading = true;
    },
    [removeProduct.fulfilled]: (state, { payload }) => {
      state.deleteProductLoading = false;
      toast.success(payload.data);
    },
    [removeProduct.rejected]: (state, { payload }) => {
      state.deleteProductLoading = false;
      toast.error(payload);
    },
    [userGetProduct.pending]: state => {
      state.userProductLoading = true;
    },
    [userGetProduct.fulfilled]: (state, { payload }) => {
      state.userProductLoading = false;
      state.products = payload?.data;
      state.pageNoBackend = payload?.totalPages;
      state.catReduxPage = payload?.totalPages;
      state.singleProduct = {};
      //  let x = [...state.products, ...payload?.data];
      // state.products = [...state.products, ...payload?.data];

      // state.products = [...state.products.concat(payload?.data)]
    },
    [userGetProduct.rejected]: (state, { payload }) => {
      state.userProductLoading = false;
      // toast.error(payload);
    },
    [userSingleProduct.pending]: state => {
      state.userSingleProductLoading = true;
    },
    [userSingleProduct.fulfilled]: (state, { payload }) => {
      state.userSingleProductLoading = false;
      state.singleProduct = payload.data;
      state.products=[]
    },
    [userSingleProduct.rejected]: (state, { payload }) => {
      state.userSingleProductLoading = false;
      // toast.error(payload);
    },
    [userCategoryProduct.pending]: state => {
      state.userCategoryProductLoading = true;
    },
    [userCategoryProduct.fulfilled]: (state, { payload }) => {
      state.userCategoryProductLoading = false;
      state.products = payload.data;
      state.catReduxPage = payload?.totalPages;
      // toast.warn(payload)
    },
    [userCategoryProduct.rejected]: (state, { payload }) => {
      state.userCategoryProductLoading = false;
      toast.error(payload);
    },
  },
});

export const {
  createProductLocal,
  deleteProductLocal,
  editProductLocal,
  clearSingleProductData,
  clearTotalPages,
  clearProduct,
  clearCategoryProducts
} = productSlice.actions;

export default productSlice.reducer;
