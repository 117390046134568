/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import Navbar from "../../../component/Navbar";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSingleProduct } from "../../../features/products/productSlice";
import { saveAs } from "file-saver";
import styled from "styled-components";
function ProductDetails() {
  const { singleProduct, isLoading } = useSelector(store => store.product);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useParams();
  useEffect(() => {
    dispatch(userSingleProduct(id.id));
  }, [id]);
  const serverShareImagesurl = [];
  for (let i = 0; i < singleProduct?.sharing_images?.length; i++) {
    serverShareImagesurl.push(
      "https://chapshop.softprodigyphp.in/uploads/" +
        `${
          singleProduct &&
          singleProduct.sharing_images &&
          singleProduct.sharing_images[i]
            ? singleProduct.sharing_images[i].filename
            : ""
        }`
    );
  }
  const download = e => {
    serverShareImagesurl.forEach((item,index) => {
     
      saveAs(item, singleProduct?.sharing_images[index].filename);
    });
     saveAs( "https://chapshop.softprodigyphp.in/uploads/" + singleProduct?.main_image[0].filename, singleProduct?.main_image[0].filename )
  };
  return (
    <>
    <Wrapper>
      <Navbar />
      {isLoading ? (
        <div className="container mt-5 text-primary">
          {" "}
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="container h-100 ">
          <button
            className="btn btn-primary text-start mt-4 mb-4"
            onClick={() => {
              navigate(
                  `/user/categorylist/products/${singleProduct?.category_id}`
                );
            }}
          >
            <span aria-hidden="true">&laquo;</span>{" "}
            <i className="bi bi-arrow-left"></i>Retour
          </button>
          {singleProduct ? (
            <div className="card  m-1 h-100 border-0">
              <div className="row h-100 ">
                <div className="col-md-5  d-flex justify-content-center align-items-center">
                  <div className="product-img">
                    <div
                      id="carouselExampleControls"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active ">
                          <img
                            src={
                              "https://chapshop.softprodigyphp.in/uploads/" +
                              `${
                                singleProduct &&
                                singleProduct.main_image &&
                                singleProduct.main_image.length > 0
                                  ? singleProduct.main_image[0].filename
                                  : ""
                              }`
                            }
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        {serverShareImagesurl &&
                          serverShareImagesurl?.map((item) => {
                            return (
                              <div key={item} className="carousel-item">
                                <img
                                  src={item}
                                  className="d-block w-100"
                                  alt={item}
                                />
                              </div>
                            );
                          })}
                      </div>
                      <button
                        className="carousel-control-prev "
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon bg-primary  rounded-circle"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                        
                      >
                        <span
                          className="carousel-control-next-icon bg-primary rounded-circle "
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center h-100 mt-3">
                  <div className="card-body mt-4 d-flex-coloumn  align-items-center h-65">
                    <h5 className="card-title fs-2">
                      {singleProduct.product_name}
                    </h5>
                    <hr />
                    <div className="d-flex justify-content-start">
                      <div className="d-flex flex-wrap text-sm ">
                        <p className="me-1 text-secondary">
                        Prix de revente minimum:{" "}
                        </p>
                        <p className="text-primary">
                           {singleProduct.reselling_price} FCFA
                         </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-baseline">
                      <h5 className="card-text fs-6 text-secondary">
                      Tailles disponlibles:
                      </h5>
                      <div className="d-flex mt-2 flex-wrap text-primary d-inline">
                        {singleProduct &&
                        singleProduct?.sizes &&
                        singleProduct?.sizes.length > 0 ? (
                          singleProduct?.sizes.map((size, index) => {
                            return singleProduct?.sizes.length - 1 === index ? (
                              <p
                                key={size}
                                className="text-sm me-1 text-primary"
                              >
                                {size}
                              </p>
                            ) : (
                              <p
                                key={size}
                                className="text-sm me-1 ms-1 text-primary"
                              >
                                {size},
                              </p>
                            );
                          })
                        ) : (
                          <p className="text-muted ms-1">Sizes not available</p>
                        )}
                      </div>
                    </div>
                    <h5 className="card-text fs-6 text-secondary d-flex align-items-baseline">
                      Product ID:{" "}
                      <p className="text-primary ms-1"> {singleProduct.sku} </p>
                    </h5>
                    <h5
                      className="btn btn-primary d-flex justify-content-center mt-3 "
                      onClick={e => {
                        download(e);
                      }}
                    >
                      Télécharger les photos
                      <span className="d-flex fs-4 mt-0 ms-2">
                        <MdOutlineDownloadForOffline />
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      </Wrapper>
    </>
  );
}
export default ProductDetails;



const Wrapper = styled.div`
 img{
  height:500px;
 } 
 .carousel {
    max-width: 370px !important;
}
`;
