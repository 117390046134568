import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGlobalFilter,
  useSortBy,
  useTable,
  usePagination,
} from "react-table";
import {
  editCategory,
  removeCategory,
} from "../../features/categories/categorySlice";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Wrapper } from "../../assets/wrappers/TableWrapper";
import TableFilter from "./TableFilter";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    previousPage,
    nextPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 11 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  return (
    <>
      <TableFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <table className="category-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {" "}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? `${String.fromCodePoint(parseInt(2193, 16))} `
                        : `${String.fromCodePoint(parseInt(2191, 16))}`
                      : `${
                          !column.disableSortBy
                            ? String.fromCodePoint(parseInt(2195, 16))
                            : ""
                        }`}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="category-pagination">
        <span></span>
        <button  className="light-btn "onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button className="light-btn" onClick={() => previousPage()} disabled={!canPreviousPage}>
          prev
        </button>
        {pageIndex + 1} of {pageOptions.length}
        <button className="light-btn" onClick={() => nextPage()} disabled={!canNextPage}>
          next
        </button>
        <button className="light-btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
      </div>
       {data.length < 1 && <div className="text-center text-secondary">No data found, please add a category </div>}
    </>
  );
}

function CategoryTable() {
  const navigate = useNavigate();

  const alertHandler = (value) => {
    confirmAlert({
      title: "Confirm to delete.",
      message:
        "Are you sure want to delete this category? Associate products will also be deleted.",
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(removeCategory(value)),
        },
        {
          label: "No",
          onClick: () => console.log(""),
        },
      ],
    });
  };

  const editButtonHandler = (values) => {
    dispatch(editCategory(values.original));
    // navigate("/category/edit");
    navigate(`/admin/category/edit/${values.original._id}`);
  };

  const dispatch = useDispatch();
  const { categories, isLoading,updateLoading } = useSelector((store) => store.category);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => categories, [categories,isLoading,updateLoading]);
  const columns = useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "category_name",
        Cell: ({cell})=>{
          const imageUrl = cell.row.original.category_image?.filename
          const completeURL =
          "https://chapshop.softprodigyphp.in/uploads/" + imageUrl;
          return (
            <div className="category-name-image">
              <img src={completeURL} alt="pic"></img>
              <p>{cell.row.original.category_name}</p>
            </div>
          );

        }
      },
      {
        Header: "Sizes",
        accessor: "sizes",
        // disableSortBy: true,
        Cell: ({ cell: { value } }) => `${value}  `,
      },

      {
        Header: "Action",
        id: "action",
        accessor: (str) => "action",
        disableSortBy: true,
        Cell: (tableProps) => {
          return (
            <>
              <button
                className="light-btn"
                onClick={() => {
                  editButtonHandler(tableProps.row);
                  
                  
                }}
              >
                Edit
              </button>{" "}
              <button
                className="light-btn-danger"
                onClick={() => {
                  alertHandler(tableProps.row.original._id);
                  // dispatch(removeCategory(tableProps.row.original._id))
                }}
              >
                Delete
              </button>
            </>
          );
        },
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories, isLoading, updateLoading]
  );

  return (
    <Wrapper>
      <Table columns={columns} data={data} />
    </Wrapper>
  );
}

export default CategoryTable;
