import styled from "styled-components";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: none;

  label {
    color: rgb(128, 128, 128);
  }

  button {
    margin: 4px;
  }

  img {
    width: 120px;
    height: 100px;
    margin-top: 10px;
  }
  hr {
    background: "lime";
    color: "lime";
    width: 100%;
    height: 3px;
  }
  span {
    color: rgb(128, 128, 128);
  }
  .uploadmain,
  input {
    display: flex;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .uploadImage {
    margin-top: 8px;
    margin-bottom: 8px;
    
  }
  .image-option{
    display:flex
  }
  .form-box {
    margin-top: 2rem;
  }

  .select-line {
    width: 38vw;
    position: relative;
    padding-bottom: 18px;
    padding-top: 2rem;

    /* height:30px; */
  }
  .catg-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .catg-select {
    width: 400px;
    display: inline-block;
    position: absolute;
    z-index: 1;
    /* margin-top:5px; */
  }
  .form-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 4px solid #ffff;
    padding: 8px;
    padding-top: 14px;
    margin: 4px;
    background: #ffff;
    border-radius: 4px;
    box-shadow: 1px 2px 10px #555557;
  }
  .tooltip-ico {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-left: 8px;
    font-size: 18px;
    font-weight: 700;
  }

  .tooltip-ico .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: rgba(34, 34, 34, 0.94);;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 1px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }

  .tooltip-ico .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltip-ico:hover .tooltiptext {
    visibility: visible;
  }
  .show-image {
    margin: 5px;
    object-fit: cover;
    object-position: center;
  }

  .shared {
    display: none;
  }
  .main-image {
    display: none;
  }
  .sharedImage-label {
    margin-top:5px;
    border: 1px solid black;
    color: black;
    padding: 0px 8px;
    background-color: #f0f4f8;
    border-radius: 3px;
    display: flex;
    height:22px;
    align-items:center;
    width:100px
  }
  .g-asterisk {
    color: red;
    font-size: 20px;
  }
  .size-selector {
    width: 600px;
    display: flex;
    flex-direction: row;
  }
  .size-label label {
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    min-width:40px;
    font-size: 16px;
  }
  .size-label label input {
    /* margin: 10px; */
   width:18px;
    /* font-size:30px; */
  }
  .size-selector {
    width: 600px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .size-label label {
    margin-right: 10px;
    margin-bottom: 10px;
    /* color:red; */
    font-size: 18px;
  }
  .size-label label input {
    margin: 5px;
    color: red;
    /* font-size:30px; */
  }
`;
export default Wrapper;
