import React, { useEffect } from "react";
import Wrapper from "../../../assets/wrappers/Categories";
import { useSelector, useDispatch } from "react-redux";
import { getCategory } from "../../../features/categories/categorySlice";
import { useNavigate } from "react-router-dom";
import CategoryTable from "../../../component/table/CategoryTable";

function Categories() {
  const { deleteLoading, isLoading,updateLoading, createLoading } = useSelector((store) => store.category);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  useEffect(() => {
    dispatch(getCategory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading, createLoading,updateLoading ]);


  return (
    <Wrapper>

    {createLoading || updateLoading ? <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>: <>
 <div className="catg-title">
        <h4>Categories</h4>
        <button className="g-btn" onClick={() => navigate("/admin/category/create")}>
          create new
        </button>
      </div>

      <br />
     

      {isLoading? <div className="container mt-5 d-flex justify-content-center">
          <div className="spinner-border " role="status">
  <span className="visually-hidden">Loading...</span>
</div>
        </div>:<CategoryTable /> }
        
</>}
     
          </Wrapper>
  );
}

export default Categories;
