import React from "react";

function TableFilter({ filter, setFilter }) {
  return (
    <div className="category-filter">
      <input
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search Here"
      />
    </div>
  );
}

export default TableFilter;
