import React, { useEffect, useState } from "react";
import Select from "react-select";
// import styled from "styled-components";
import CreateProductForm from "../../../component/CreateProductForm";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../features/categories/categorySlice";
import {
  createProduct,
  createProductDraft,
  getProduct,
} from "../../../features/products/productSlice";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ButtonDrop from "../../../component/button";
import Wrapper from "../../../assets/wrappers/ProductFrom";

const ProductsCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const { categories } = useSelector(store => store.category);
  const { products } = useSelector(store => store.product);

  const filteredCategory = categories?.filter(
    item => item._id === selectedOption?.id
  );

  const arraySorted = [];
  categories.map(item => arraySorted.push([item.category_name, item._id]));
  const sortedCategory = arraySorted?.sort();
  const SelectionArray = [];
  sortedCategory.forEach(item =>
    SelectionArray.push({
      value: item[0],
      label: item[0],
      id: item[1],
    })
  );

  const [selectImage, setSelectImage] = useState([]);
  const [selectImages, setSelectImages] = useState([]);
  const [imageURL, setImageURL] = useState();
  const [file, setFile] = useState([]);
  const [checkSize, setCheckSize] = useState([]);
  let validationError  =false

  const checkHandler = e => {
    const { value, checked } = e.target;
    if (checked) setCheckSize([...checkSize, value]);
    else {
      setCheckSize(checkSize.filter(e => e !== value));
    }
  };

  useEffect(() => {
    dispatch(getCategory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [finputValues, fsetInputValues] = useState({
    productName: "",
    BuyingPrice: "",
    ResellingPrice: "",
    sku: "",
    mainImage: [],
    SharingImage: [],
  });

  const productNameRegex = /^[a-z A-Z 0-9 á-ú Á-Ú à-ù â-û À-Ù Ä-Ü çëüÿñæœæœœ ' & .-]*$/;
  const notOnlyNumRegex = /(?!^\d+$)^.+$/i;
  const buyingPriceNum = Number(finputValues.BuyingPrice);
  const resellingPriceNum = Number(finputValues.ResellingPrice);

  const arr = finputValues.productName.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase().trim() + arr[i].slice(1).trim();
  }
  const finalProductName = arr.join(" ").trim();

  const productInputHandler = e => {
    const { name, value } = e.target;
    fsetInputValues({ ...finputValues, [name]: value });
  };


  const validatorFunction = ()=>{
    if (
      products?.some(
        e =>
          e.product_name.toLowerCase().trim() ===
          finputValues.productName.toLowerCase().trim()
      )
    ) {
      validationError=true
      return toast.warn("Product name already exists.");
    }

    if (selectedOption === null) {
      validationError=true
      return toast.warning("Select a category");
    }
    if (finputValues.productName.trim() === "") {
      validationError=true
      return toast.warning("Enter product name");
    }
    
    if (finputValues.productName.trim().length < 3) {
      validationError=true
      return toast.warning("Product name should contain minimum 3 character.");
    }
    if (!productNameRegex.test(finputValues.productName)) {
      validationError=true
      return toast.warning("This is not a valid product name.");
    }
    if (!notOnlyNumRegex.test(finputValues.productName)) {
      validationError=true
      return toast.warning("Only numbers are not allowed as product name");
    }
    if (finputValues.sku < 3) {
      validationError=true
      return toast.warning("SKU should contain minimum 3 characters");
    }
    if (!productNameRegex.test(finputValues.sku)) {
      validationError=true
      return toast.warning("This is not a valid SKU.");
    }
    if (finputValues.BuyingPrice === "") {
      validationError=true
      return toast.warning("Enter buying price");
    }
    if (buyingPriceNum <= 0) {
      validationError=true
      return toast.warn("Negative or zero buying price is not allowed.");
    }
    if (finputValues.ResellingPrice === "") {
      validationError=true
      return toast.warning("Enter reselling price.");
    }
    if (resellingPriceNum <= 0) {
      validationError=true
      return toast.warning("Negative or zero reselling price is not allowed.");
    }
    if (resellingPriceNum < buyingPriceNum) {
      validationError=true
      return toast.warning(
        "Reselling price should not be less than buying price."
      );
    }
    if (selectImage.length === 0) {
      validationError=true
      return toast.warn("Please upload main image.");
    }

    if (selectImages.length > 5) {
      validationError=true
      return toast.warn("More than 5 sharing images are not allowed.");
    }

    if (selectImages.length === 0) {
      validationError=true
      return toast.warn("Please upload sharing image.");
    }
  }

  const handleDraft = () => {
   validatorFunction()
   if(validationError){
    return
   }
    dispatch(
      createProductDraft({
        product_name: finalProductName,
        sku: finputValues.sku,
        buying_price: finputValues.BuyingPrice,
        reselling_price: finputValues.ResellingPrice,
        category_id: selectedOption.id,
        mainImage: selectImage,
        sharingImages: selectImages,
        sizes: checkSize,
        is_draft: true,
      })
    );
    navigate("/admin/product");
  };

  const handleSubmit = () => {
    validatorFunction()
    if(validationError){
      return
     }
    dispatch(
      createProduct({
        product_name: finalProductName,
        sku: finputValues.sku,
        buying_price: finputValues.BuyingPrice,
        reselling_price: finputValues.ResellingPrice,
        category_id: selectedOption.id,
        mainImage: selectImage,
        sharingImages: selectImages,
        sizes: checkSize,
        is_draft: false,
      })
    );
    navigate("/admin/product");
  };
  const handleBack = () => {
    localStorage.removeItem("product_id");
    navigate("/admin/product");
  };

  const mainImageHandler = e => {
    const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
    const file = e.target.files[0];
    if (!file?.type.match(imageMimeType)) {
      toast.warning("Invalid image type, only .jpeg/.jpg/.png are allowed");
      return setFile([]);
    }
    const test = Object.values(e.target.files);
    let error = false;
    test.forEach(item => {
      if (item.size > 2000000) {
        error = true;
        setSelectImage([]);
        return toast.warn("Image size should be less than 2 mb.");
      }
    });
    if (error === true) {
      return;
    }
    setSelectImage([...e.target.files]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };

  const sharingImageHandler = e => {
    setFile([]);
    setSelectImages([]);
    const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      toast.warning("Invalid image type, only .jpeg/.jpg/.png are allowed");
      return setFile([]);
    }

    const test = Object.values(e.target.files);
    let error = false;
    test.forEach(item => {
      if (item.size > 2000000) {
        error = true;
        setSelectImages([]);
        return toast.warn("Image size should be less than 2 mb.");
      }
    });
    if (error === true) {
      return;
    }
    if (e.target.files.length > 5) {
      setSelectImages([]);

      return toast.warn("More than 5 images are not allowed.");
    }

    setSelectImages([...e.target.files]);

    for (let i = 0; i < e.target.files.length; i++) {
      const newImageURL = URL.createObjectURL(e.target.files[i]);
      setFile(prevState => [...prevState, newImageURL]);
    }
  };
  return (
    <Wrapper>
      <h2 style={{ textAlign: "center" }}>Create a new product.</h2>
      <hr />
      <br />
      <div className="form-card">
        <div className="form-box">
          <div className="select-line">
            <label>Category</label> <span className="g-asterisk">*</span>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              placeholder="Select for category specific products"
              isClearable
              options={SelectionArray}
            />
          </div>
          <div className="main-product-input">
            <CreateProductForm
              onProductInputHandler={productInputHandler}
              imp={true}
            />
            <div className="uploadmain-container">
              <span>Select Sizes </span> 
              {/* <span className="g-asterisk">*</span> */}
              <div className="size-selector">
                {filteredCategory[0]?.sizes.map((item, index) => {
                  return (
                    <div className="size-label" key={index}>
                      {" "}
                      <label htmlFor={item}>
                        <input
                          id={item}
                          value={item}
                          type="checkbox"
                          onChange={e => checkHandler(e)}
                        />
                        {item}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="uploadImage">
              <div className="image-option"> 
                <span>Main Image </span>
                <span className="g-asterisk">*</span>
                <em className="tooltip-ico">
                  i{" "}
                  <span className="tooltiptext">Accepted image size : 2MB</span>
                </em>
                <input
                  type="file"
                  id="main"
                  accept=".png, .jpg, .jpeg, .webp"
                  className="main-image"
                  onChange={e => mainImageHandler(e)}
                />
                <label htmlFor="main" className="sharedImage-label">
                  Choose File
                </label>
              </div>

                <div>
                  {imageURL ? (
                    <img className="show-image" src={imageURL} alt="main" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="uploadImage">
              <div className="image-option"> 
                <span>Sharing Image </span>{" "}
                <span className="g-asterisk">*</span>{" "}
                <em className="tooltip-ico">
                  i{" "}
                  <span className="tooltiptext">Accepted image size : 2MB</span>
                </em>
                <input
                  type="file"
                  className="shared"
                  multiple
                  accept=".png, .jpg, .jpeg, .webp"
                  id="sharingImageInput"
                  onChange={e => sharingImageHandler(e)}
                />
                <label
                  htmlFor="sharingImageInput"
                  className="sharedImage-label"
                >
                  Choose File
                </label>
              </div>
                <div>
                  {file &&
                    file?.map((item, index) => {
                      return (
                        <img
                          className="show-image"
                          src={item}
                          key={index}
                          alt="sdf"
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="catg-title">
              <button
                className="g-btn"
                onClick={() => {
                  handleBack();
                }}
              >
                Retour
              </button>
              <ButtonDrop
                handleSubmit={handleSubmit}
                handleDraft={handleDraft}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductsCreate;
