/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Navbar from "../../../component/Navbar";
import {
  clearTotalPages,
  userGetProduct,
  clearCategoryProducts,
} from "../../../features/products/productSlice";
import Sorting from "../../../component/sorting";
import debounce from "lodash.debounce";

const ListingProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSingleProduct = props => {
    navigate(`/user/productdetail/${props}`);
  };
  const { categories } = useSelector(store => store.category);
  const {
    products,
    pageNoBackend,
   
  } = useSelector(store => store.product);
  const [search, setSearch] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const id = useParams();
  const categoryId = id.id;
  const [productList, setProductList] = useState([]);
  const [scroll, setScroll] = useState(window.scrollY);

  const listRef = useRef();

  const queryParamsCategory =
    "?category_id=" +
    categoryId +
    "&filter=" +
    selectedSort +
    "&product_name=" +
    search +
    "&page=" +
    pageNo;

  useEffect(() => {
    if (categories) {
      dispatch(userGetProduct(queryParamsCategory));
    }
  }, [selectedSort, search, pageNo, categoryId]);

  const handleSort = value => {
    return(setProductList([]), setSelectedSort(value)) 
  };

  useEffect(() => {
    setProductList([...productList.concat(products)]);
  }, [products]);

 
  const getNextPage = () => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    // setScroll(scrollTop);
    // let x = scrollHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (pageNo < pageNoBackend) {
        setPageNo(pageNo + 1);
      }
    }}

    useEffect(() => {
      if (pageNoBackend > 1) {
        window.addEventListener("scroll", getNextPage);
        return () => window.removeEventListener("scroll", getNextPage);
      }
    }, [scroll, pageNoBackend, pageNo]);
  

  const searchHandler = e => {
    return (
      setProductList([]), setSearch(e.target.value), setPageNo(1)
    );
  };

  const debounceSearchHandler = debounce((e)=>{
      searchHandler(e)
  },500)
  const handleBack = () => {
    return (
      setProductList([]),
      clearTotalPages(),
      navigate("/"),
      dispatch(clearCategoryProducts())
    );
  };

  return (
    <Wrapper>
      <Navbar />
      <div className="container">
        <button
          className="btn btn-primary text-start mt-4 mb-4"
          onClick={e => {
            handleBack();
          }}
        >
          <span aria-hidden="true">&laquo; Retour</span>
        </button>
        <div className="row text-end d-flex justify-content-between">
          {/* <div className="ol-sm col-md-4 text-start"></div> */}
          <div className="input  col-md-6 mt-2 mb-2 h-4">
            <input
              type="search"
              style={{ height: "42px" }}
              // value={search}
              onChange={e => {
                debounceSearchHandler(e);
              }}
              className="form-control me-2"
              placeholder="Search"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
          </div>
          <div className="text-end col-sm-12 col-md mt-2">
            <Sorting handleSort={handleSort} />
          </div>
        </div>
        {!products ? (
          <div className="col-md-12 text-secondary"> No product found </div>
        ) : (
          <div className="row mt-2" ref={listRef}>
            {productList && productList[0] ? (
              productList.map(newProduct => {
                return (
                  <div key={newProduct?._id} className="col-md-3">
                    <div
                      className="card border-1 py-2 custom-card"
                      role="button"
                      onClick={() => {
                        handleSingleProduct(newProduct?._id);
                      }}
                    >
                      <div className="product-img">
                        <img
                          className="card-img-top rounded-2 custom-card-img"
                          src={
                            "https://chapshop.softprodigyphp.in/uploads/" +
                            `${
                              newProduct &&
                              newProduct?.main_image &&
                              newProduct.main_image[0]
                                ? newProduct?.main_image[0].filename
                                : ""
                            }`
                          }
                          alt={newProduct?.product_name}
                        />
                      </div>
                      <div className="card-body">
                        {newProduct?.product_name.trim().length >= 20 ? (
                          <h5 className="card-title text-dark">
                            {newProduct?.product_name.substring(0, 17)}...
                          </h5>
                        ) : (
                          <h5 className="card-title text-dark">
                            {newProduct?.product_name}
                          </h5>
                        )}
                        <span className="text-secondary">
                        Prix de revente minimum:{" "}
                          <p className="text-primary d-inline fw-bold">
                            {" "}
                            {newProduct?.reselling_price} FCFA{" "}
                          </p>{" "}
                        </span>
                        <div className="d-flex mt-2 flex-wrap">
                          <div className="text-sm me-1 text-secondary fs-6">
                          Tailles disponlibles:{" "}
                          </div>
                          <span className="d-flex flex-wrap text-wrapp">
                            {newProduct &&
                            newProduct.sizes &&
                            newProduct.sizes.length > 0 ? (
                              newProduct.sizes.map((size, index) => {
                                return newProduct.sizes.length - 1 === index ? (
                                  <p
                                    key={size}
                                    className="text-sm me-1 text-primary fw-bold"
                                  >
                                    {size}
                                  </p>
                                ) : (
                                  <p
                                    key={size}
                                    className="text-sm me-1 text-primary fw-bold"
                                  >
                                    {size},
                                  </p>
                                );
                              })
                            ) : (
                              <p className="text-muted text-primary">
                                Sizes not available
                                </p>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="spinner-border text-secondary m-5" role="status">
                <span className="visually-hidden">Loading</span>
              </div>
            )}
          </div>
        )}{" "}
      </div>
    </Wrapper>

  );
};

export default ListingProduct;

const Wrapper = styled.div`
  .product-img {
    width: 100%;
  }
  .custom-card {
    background: #fbfbfb;
    height: 95%;
    //  width: 250px;
    margin-bottom: 17px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding-top: 0 !important;
    border-radius: 0.3rem;
    &:hover {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 2px 9px rgb(0 0 0 / 24%);
    }
  }
  .custom-card .card-title {
    letter-spacing: 0.1px;
  }
  .custom-card-img {
    object-fit: scale-down;
    width: 100%;
    height: 335px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .text-wrapp{
    word-break: break-all;
  }
  .card-body {
    max-height: 150px !important;
    overflow: auto !important;
}
div::-webkit-scrollbar {
  width: 2px;
}

div::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

div::-webkit-scrollbar-thumb {
  border-radius:2px;
  background-color: gray;
  outline: 1px solid gray;
}
`;

