import styled from "styled-components";

export const Wrapper = styled.div`
/* background-color:yellow; */
/* box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.5); */
width:100%;

table {
  font-family:   Helvetica, sans-serif;
  border-collapse: collapse;
  background-color:white;
  width: 100%;
  border-radius: 10px;
  text-align:center;
  box-shadow: 0 0 10px 1px var(--grey-200);
}



thead{
    /* background-color:white; */
    color:gray; 
    font-weight:normal;
    height:60px;
    border-bottom:1px solid var(--grey-100);
    word-spacing:5px;
    /* font-size:15px; */
}

th td{
    padding:10px;
    font-weight:100;
    border-bottom: 2px solid black; 
    /* color:red; */
}
tbody tr{
    height:40px;
    font-weight:100;
    border-bottom:1px solid var(--grey-100);
}

tbody tr:last-child{
    border-bottom:none;
}

.category-table tbody td{
  min-width: 300px;
  max-width:400px;
  overflow-wrap: break-word;
}

.product-table tbody td{
  min-width:100px;
  max-width:200px;
  overflow-wrap: break-word;  
}

.category-filter{
  /* border:1px solid red; */
  display:flex;
  align-items:center;
  justify-content:flex-end;
  text-align:left;
  margin-bottom:20px;
  padding:5px;
  height:45px;
  background-color:1px solid black;
}
.search-icon{
  font-size:25px;
}
.category-filter input{
  width:25%;
  border:1px solid #9fb3c8;
  height:30px;
  border-radius:4px;
  height:40px;
  /* padding:10px; */
  text-align:center;
  vertical-align:center;
  /* margin-right:10px; */
}

.category-filter input:focus{
  outline: none !important;
    border:2px solid var(--primary-500);
}


.category-pagination{
  width:100%;
  display:flex;
  margin-top:1rem;
  justify-content:flex-end;
}
.category-pagination *{
  margin:0px 5px;
}

.category-name-image{
display:flex;
flex-direction:row;
justify-content:center;
padding-top:3px;
padding-bottom:3px;
padding-left:5px;
align-items:center
}
.category-name-image img{
    width:60px;
    height:60px;
    border-radius:80px;
    margin-right:20px;
   
}
.category-name-image p{
  min-width:150px;
  max-width:350px;
  width:100px;
  text-align:left;
  word-break: break-all;
  /* color:red; */
  margin-top:0;
    margin-bottom:0;
  white-space: normal;
  word-break: break-all;
 
}

.product-name-image{
display:flex;
flex-direction:row;
justify-content:center;
padding-top:3px;
padding-bottom:3px;
padding-left:5px;
align-items:center
}
.product-name-image img{
    width:60px;
    height:60px;
    border-radius:80px;
    margin-right:20px;
   
}
.product-name-image p{
  min-width:100px;
  max-width:150px;
  width:100px;
  text-align:left;
  word-break: break-all;
  /* color:red; */
  margin-top:0;
    margin-bottom:0;
  white-space: normal;
  word-break: break-all;
 
}




.draft-item{
  border-bottom:3px solid red;
  width:40px;
  margin:auto;
  align-items:center;
  text-align:center;

}
.published-item{
  border-bottom:3px solid green;
  width:40px;
  margin:auto;
  align-items:center;
  text-align:center;

}
.no-data{
  text-align:center;
  font-size:30px;
}
/* 
th, td {
  padding: 10px;
  
  border-bottom: 2px solid var(--grey-100); 
} */
/* CB2027 */




`