import { configureStore } from "@reduxjs/toolkit";
import categorySlice from "./features/categories/categorySlice";
import productSlice from "./features/products/productSlice";
import userSlice from "./features/user/userSlice";
export const store = configureStore({
    reducer:{
        user:userSlice,
        category:categorySlice,
        product:productSlice,   
    }
})