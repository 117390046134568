import styled from "styled-components";


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  border-radius: 20px;
  padding: 1rem;
  color: black;
  background: var(--white);
  /* min-height: 80vh; */
  label {
    color: rgb(128, 128, 128);
  }
  button{
    margin-top:5px
  }
  form{
    width:100%
  }
  .cat-fields {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .form-row{
    display:flex;
    justify-content:space-around;
    flex-direction:row;
    /* gap:60px; */
    width:100%;
    margin-top:20px;
  }
  .cat-input {
    border: 1px solid grey;
    min-height: 2.9rem;
    border-radius:4px;
    min-width: 300px;
    width:400px;
    word-wrap: break-word;
    padding: 10px;
    font-family: var(--headingFont);
    font-weight: 400;
  }
  input:focus{
    outline: none !important;
    border:1px solid var(--primary-500);
    min-height: 3rem;
    
  }
  .size-input-form{
    /* border:1px solid; */
    display:flex;
    align-items: center;
    
  }
  .size-input{
    border: 1px solid grey;
    min-height: 3rem;
    border-bottom-left-radius:4px;
    border-top-left-radius:4px;
    /* min-width: 400px; */
    min-width:100px;
    width:220px;
    word-wrap: break-word;
    padding: 10px;
    font-family: var(--headingFont);
    font-weight: 400;
    border-right:none;
  }
  .size-tags-list{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap; 
    width:320px;
    justify-content:flex-start;

  }

  .size-list{
    /* border: 1px solid grey; */
    text-align:center;
    display:flex;
    flex-wrap:wrap;
    margin:2px;
    align-items:center;
    justify-content:center;
    min-height: 2rem;
    height:auto;
    border-radius:4px;
    /* min-width: 400px; */
    min-width:50px;
    max-width:178px;
    word-wrap: break-word;
    padding: 5px;
    /* margin-left: 150px; */
    /* margin:3px; */
    font-family: var(--headingFont);
    font-weight: 400;
    border-top:none;
    background-color:lightblue;
    display:flex; 
    justify-content:space-between   ;
    /* margin-top:5px; */
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .size-list div{
    min-width:25px;
    max-width:150px;
    margin-right:5px;
  }
  .delete-size{
  font-size: 18px;
  /* float:right; */
 }
  .delete-size:hover {
  color:red;
 }

  .size-btn{
  cursor: pointer;
  color: var(--white);
  background: var(--grey-300);
  border:none;
  border: 1px solid grey;
  border-top-right-radius:4px;
  border-bottom-left-radius:4px;
  width:100px;
  /* border-radius: var(--borderRadius); */
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
      min-height: 3rem;
      /* margin:0px 10px; */
      margin-left:0px;
      margin-top:0px;
      border-left:none;

  }

  .c-btn{
    margin-top:30px;
    width:100px;
    height:40px;
    cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
  }

  .c-btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}

  .added-item-card{
    /* border: 1px solid rgb(172, 172, 172);; */
    min-width:400px;
    min-height:50px;
    display:flex;
    flex-direction:row;
    justify-content:space-evenly

  }
  /* section div:first-child{
    border-right:1px solid grey
  } */
  .added-item-card div{
    width:100%
  }
  .added-items{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    text-align:center;
    max-width:200px;
    word-wrap:break-word;
  }
  .added-items .right-border{
    padding:5px;
   
  }
  .added-items p{
    
    background-color:#bfdbfe;
    border-radius:5px
  }
  .added-items .left-border{
    border-left: 1px solid grey
  }

  .sharedImage-label {
    margin-top:5px;
    border: 1px solid black;
    color: black;
    padding: 0px 8px;
    background-color: #f0f4f8;
    border-radius: 3px;
    display: flex;
    height:22px;
    align-items:center;
    width:100px
  }
  .g-asterisk{
    color: red;
    font-size: 20px;
  }

  .categoryUploadImage {
    margin-top: 15px;
    margin-bottom: 8px;
    
  }
  .image-option{
    display:flex
  }
  .image-option span{
    color: rgb(128, 128, 128);
  }


  .tooltip-ico {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-left: 8px;
    font-size: 18px;
    font-weight: 700;
  }

  .tooltip-ico .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: rgba(34, 34, 34, 0.94);;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 1px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }

  .tooltip-ico .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltip-ico:hover .tooltiptext {
    visibility: visible;
  }
  .main-image {
    display: none;
  }
  .show-image {
    margin: 5px;
    object-fit: cover;
    object-position: center;
  }
  img {
    width: 120px;
    height: 100px;
    margin-top: 10px;
  }
 @media (min-width: 992px) {
    .page {
      grid-template-columns: 1fr 1fr;
      column-gap: 3rem;
    }
    .main-img {
      display: block;
    }}

`;

export default Wrapper