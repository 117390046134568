import React from 'react'
import Wrapper from '../assets/wrappers/Navbar'
import { FaAlignLeft, FaUserCircle, FaCaretDown } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSidebar, logOutUser } from '../features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { clearProduct } from '../features/products/productSlice';


function Navbar() { 
    const {user} = useSelector((store)=>store.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
  return (
    <Wrapper>
    <div className='nav-center'>

    {user&&<button type='button' className='toggle-btn' onClick={()=> dispatch(toggleSidebar())}>
        <FaAlignLeft />
      </button> }
      
      <div className='user-logo-text'> 
       {user ? <h3 className='logo-text'>Admin Panel</h3>: <h3 >ChapShop</h3>}
      </div>
      <div className='g-btn-container'>
       
          {user ?
            <div className="dropdown">
  <button className="g-btn user-name"> <FaUserCircle />
          {user?.firstName}
          <FaCaretDown /></button>
 
  <button className="dropdown-content logout-button"  onClick={() => {
             dispatch(logOutUser());
             dispatch(clearProduct())
             navigate('/')
            }}>Log out</button>
 
</div> : 
 ''
}
      </div>
    </div>
  </Wrapper>
  )
}

export default Navbar

