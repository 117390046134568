import NavLinks from './NavLinks';
import Logo from '../component/Logo'
import Wrapper from '../assets/wrappers/BigSidebar';
import { useSelector } from 'react-redux';


function BigSidebar() {
  const { isSidebarOpen } = useSelector((store) => store.user);

  return (
    <Wrapper>
      <div
        className={
          isSidebarOpen
            ? 'sidebar-container '
            : 'sidebar-container show-sidebar'
        }
        // className= "sidebar-container show-sidebar"
        
      >
        <div className='content'>
          <header>
            <Logo />
          </header>
          <NavLinks />
        </div>
      </div>
    </Wrapper>
  )
}

export default BigSidebar