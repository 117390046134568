import React, { useState } from "react";
// import { useSelector } from "react-redux";
import styled from "styled-components";

function CreateProductForm(props) {
  // const { singleProductData } = useSelector((store) => store.product);

  const [inputValues, setInputValues] = useState({
    productName: props.prodName ? props.prodName : "",
    BuyingPrice: props.buyingPrice ? props.buyingPrice : "",
    ResellingPrice: props.resellingPrice ? props.resellingPrice : "",
    sku: props.sku ? props.sku : "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
    props.onProductInputHandler(e);
  };

  // setInputValues({...inputValues, [inputValues.]})

  document.querySelectorAll('input[type="number"]').forEach((input) => {
    input.oninput = () => {
      if (input.value.length > input.maxLength)
        input.value = input.value.slice(0, input.maxLength);
    };
  });

  return (
    <Wrapper>
      <section>
        <label htmlFor="productName">Product Name</label>{" "}
        {props.imp && <span className={"g-asterisk"}>*</span>}
        <div>
          <input
            className="product-input"
            type="text"
            id="category"
            maxLength="75"
            name="productName"
            value={inputValues.productName}
            // defaultValue={
            //   singleProductData ? singleProductData?.product_name
            //     : inputValues.productName
            // }
            placeholder="Enter new Product"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </section>
      {/* sdfsdfasdfdfsdfadfdfdf */}
      <section>
        <label htmlFor="sku">SKU</label> 
        {props.imp && <span className={"g-asterisk"}>*</span>}

        <div>
          <input
            className="product-input"
            type="text"
            id="sku"
            maxLength="30"
            name="sku"
            value={inputValues.sku}
            // defaultValue={
            //   singleProductData ? singleProductData?.product_name
            //     : inputValues.productName
            // }
            placeholder="Enter new SKU"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </section>

      <div className="price">
        <section>
          <label htmlFor="BuyingPrice">Buying Price</label>{" "}
          {props.imp && <span className={"g-asterisk"}>*</span>}
          <div>
            <input
              className="price-input"
              type="number"
              id="category"
              maxLength="7"
              name="BuyingPrice"
              value={inputValues.BuyingPrice}
              // defaultValue={singleProductData?.buying_price}
              placeholder="Enter buying price"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </section>

        <section>
          <label htmlFor="ResellingPrice">Reselling Price</label>{" "}
          {props.imp && <span className={"g-asterisk"}>*</span>}
          <div>
            <input
              className="price-input"
              type="number"
              id="category"
              name="ResellingPrice"
              // defaultValue={ singleProductData?.reselling_price }
              value={inputValues.ResellingPrice}
              placeholder="Enter reselling price"
              maxLength="7"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

export default CreateProductForm;

const Wrapper = styled.div`
  /* border: 1px solid black; */
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  label {
    color: rgb(128, 128, 128);
  }
  .product-input {
    border: 1px solid #949393;
    border-radius: 4px;
    height: 40px;
    min-width: 38vw;
    box-shadow: var(--shadow-1);
    margin-top: 4px;
  }
  .price-input {
    border: 1px solid #949393;
    border-radius: 4px;
    height: 40px;
    width: 12vw;
    min-width: 18.7vw;
    box-shadow: var(--shadow-1);
    margin-top: 4px;
    margin-right: 10px;
  }
  input {
    padding-left: 8px;
  }
  .price {
    display: flex;
  }
  section {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 14x;
    padding-bottom: 14px;
  }
  .g-asterisk {
    color: red;
    font-size: 20px;
  }
`;
